import React, { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { getReport } from '../../api/bot.api';
import { displayWithNonZeroDecimals, formatDateTime, shortenAddress } from '../../libs/utils';
import { toast } from 'react-toastify';
import { HistoryInterface } from '../historyPage/HistoryContainer';

const actionStr: { [key: string]: string} = { rebalance: 'RE-BALANCE', collect: 'HARVEST-ONLY', close: 'CLOSE-POSITION', stop: 'CLOSE-ON-PRICE'};

export default function ReportContainer() {
    const [histories, setHistories] = React.useState<HistoryInterface[]>([]);
    const [filteredHistories, setFilteredHistories] = React.useState<HistoryInterface[]>([]);
    const [txCount, setTxCount] = useState<number>(100);
    const [totalProfit, setTotalProfit] = useState<number>(0);
    const [totalConverted, setTotalConverted] = useState<number>(0);
    const [note, setNote] = useState<string>("");
    const [address, setAddress] = useState<string>("all");
    const [addressList, setAddressList] = useState<string[]>(['all']);
    const [isApplying, setIsApplying] = useState<boolean>(false);

    const handleApply = async () => {
        setIsApplying(true);
        const res = await getReport({count: txCount ? txCount : 100});
        if(res.success){
            setHistories(res.data);
        }else{
            setHistories([]);
            toast.error(`Error on getting repor - ${res.msg}`);
        }
        setIsApplying(false);
    }

    const handleAddressChange = (value: string) => {
        setAddress(value);
    }

    const handleTxCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!isNaN(Number(value))) {
            setTxCount(Number(value));
        } else {
            return;
        }
    }

    useEffect(() => {
        // Get unique wallet addresses from histories
        const uniqueAddresses = Array.from(new Set(histories.map(history => history.data?.walletAddress)))
            .filter(address => address !== undefined) as string[];
        
        // Update addressList state with unique addresses
        setAddressList([...uniqueAddresses]);

        // Calculate total profit for the selected address or all addresses
        const filteredHistories = address === 'all' 
            ? [...histories] 
            : histories.filter(history => history.data?.walletAddress === address);

        setFilteredHistories(filteredHistories);

        const totalConverted = filteredHistories.reduce((sum, history) => {
            const profit = history.data?.converted || 0;
            return sum + profit;
        }, 0);
        setTotalConverted(Number(displayWithNonZeroDecimals(totalConverted, 1)));

        const totalProfit = filteredHistories.reduce((sum, history) => {
            const profit = history.data?.difference || 0;
            return sum + profit;
        }, 0);
        setTotalProfit(Number(displayWithNonZeroDecimals(totalProfit, 1)));

    }, [histories, address])

    return (
        <Box pt={2} sx={{width: '100%', flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
            <Box display='flex' px={4} width={'100%'}>
                <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'} mr={4}>
                        <Typography variant='subtitle2' mr={2}>TOTAL TRANSACTIONS TO DISPLAY:</Typography>
                        <TextField size='small' value={txCount} onChange={handleTxCountChange}/>
                    </Box>
                    <LoadingButton sx={{width: '120px'}} loading={isApplying} loadingIndicator="Applying..." variant="outlined" onClick={handleApply}>
                            Apply
                    </LoadingButton>
                </Box>
                <Stack flex={1} spacing={1} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant='subtitle2'>TOTAL Converted USDT: </Typography>
                        <Typography ml={2}> {totalConverted}</Typography>
                    </Box>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography variant='subtitle2'>TOTAL PROFITS: </Typography>
                        <Typography ml={2}>{totalProfit}</Typography>
                    </Box>
                </Stack>
            </Box>
            <Box display={'flex'} alignItems={'center'} mt={2}  px={4} >
                <Typography variant='subtitle2' mr={2}>TEXT NOTE:</Typography>
                <TextField size='small' variant='standard' sx={{width: '60%'}} value={note} onChange={(e)=>setNote(e.target.value)}/>
            </Box>
            <Box display={'flex'} alignItems={'center'} mt={2}  px={4} >
                {/* <Typography variant='subtitle2' mr={2}>Wallet Address:</Typography> */}
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel>Wallet Address</InputLabel>
                    <Select
                        value={address}
                        onChange={(e)=>handleAddressChange(e.target.value)}
                        label="Wallet Address"
                        >
                        <MenuItem value="all">All</MenuItem>
                        {addressList.map((addr) => (
                            <MenuItem key={addr} value={addr}>
                                {shortenAddress(addr, 4)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <Box mt={4} pb={1} sx={{display: 'flex', flexDirection: 'column', width: '100%', overflowY: 'auto', flex: 1,
                '& table': {
                    border: '1px solid blue',
                    borderColor: 'divider',
                    borderRadius: '12px 12px 0 0',
                    borderBottom: 'none',
                    thead: {
                        height: '50px',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#1a1a1a',
                        borderRadius: '12px',
                        zIndex: 1,
                    },
                    th: {
                        color: 'text.disabled',  
                    },
                    tr: {
                        height: '50px',
                    },
                    td: {
                        textAlign: 'center',
                        borderBottom: '1px solid blue',
                        borderBottomColor: 'divider',
                        
                    }
                },
             }}>
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>DATE</th>
                            <th>ADDRESS</th>
                            <th>ACTION</th>
                            <th>EX-POOL</th>
                            <th>YIELD</th>
                            <th>P/L USDT</th>
                            <th>LP BALANCE</th>
                            <th>WALLET</th>
                            <th>ALL POOLS</th>
                            <th>TOTAL</th>
                            <th>AFTER REBAL</th>
                            <th>PLUS</th>
                            {/* <th>SWAP MINUS</th> */}
                            <th>Deposit %</th>
                            <th>NFT ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHistories.map((info, ind) => (
                            <tr key={info._id}>
                                <td style={{minWidth: '30px'}}>
                                    {ind + 1}
                                </td>
                                <td>{formatDateTime(info.createdAt)}</td>
                                <td style={{maxWidth: '100px'}}>{shortenAddress(info.data.walletAddress, 4)}</td>
                                <td>{actionStr[info.commandType]}</td>
                                <td style={{maxWidth: '100px'}}>
                                    <Stack>
                                        <Typography>{info.data.symbolA}/{info.data.symbolB}</Typography>
                                        {info.data.prices &&
                                            <Typography variant="caption" color='divider'>
                                                {displayWithNonZeroDecimals(info.data.prices[info.data.symbolA], 1) || '_'} / {displayWithNonZeroDecimals(info.data.prices[info.data.symbolB], 1) || '_'}
                                            </Typography>
                                        }
                                    </Stack>
                                </td>
                                <td>${displayWithNonZeroDecimals(info.data.totalYield, 1)}</td>
                                <td>{displayWithNonZeroDecimals(info.data.converted, 1)}</td>
                                <td>
                                    <Stack>
                                        <Typography>${displayWithNonZeroDecimals(info.data.balance, 1)}</Typography>
                                        <Typography variant="caption" color='divider'>{displayWithNonZeroDecimals(info.data.afterPositionBalance, 1)}</Typography>
                                    </Stack>
                                </td>
                                <td>${displayWithNonZeroDecimals(info.data.walletBalance, 1)}</td>
                                <td>${displayWithNonZeroDecimals(info.data.poolBalance, 1)}</td>
                                <td>${displayWithNonZeroDecimals(info.data.beforeTotalBalance, 1)}</td>
                                <td>
                                    <Stack>
                                        <Typography>${displayWithNonZeroDecimals(info.data.afterTotalBalance, 1)}</Typography>
                                        {info.data.afterPrices &&
                                            <Typography variant="caption" color='divider'>
                                                {displayWithNonZeroDecimals(info.data.afterPrices[info.data.symbolA], 2) || '_'} / {displayWithNonZeroDecimals(info.data.afterPrices[info.data.symbolB], 2) || '_'}
                                            </Typography>
                                        }
                                    </Stack>
                                </td>
                                <td>{displayWithNonZeroDecimals(info.data.difference, 1)}</td>
                                {/* <td>
                                    <Stack>
                                        <Typography>{displayWithNonZeroDecimals(info.data.swapMinus, 1)}</Typography>
                                        <Typography variant="caption" color='divider'>{displayWithNonZeroDecimals(info.data.swapMinusJup, 1)}</Typography>
                                    </Stack>
                                </td> */}
                                <td>
                                    <Stack>
                                        <Typography>
                                            {info.data.realDepositPercent !== undefined && (
                                                <>
                                                    {info.data.realDepositPercent} / {100 - info.data.realDepositPercent}
                                                </>
                                            )}
                                            {info.data.realDepositPercent === undefined && info.commandType === 'rebalance' && info.data.settings?.depositPercent !== undefined && (
                                                <>
                                                    {info.data.settings.depositPercent} / {100 - info.data.settings.depositPercent}
                                                </>
                                            )} 
                                        </Typography>
                                        <Typography variant="caption" color='divider'>
                                            {info.data.depositAmountA !== undefined && (
                                                <>
                                                    {displayWithNonZeroDecimals(info.data.depositAmountA, 1)} / {displayWithNonZeroDecimals(info.data.depositAmountB, 1)}
                                                </>
                                            )}
                                        </Typography>
                                    </Stack>
                                    
                                </td>
                                <td style={{maxWidth: '100px'}}>
                                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography>{shortenAddress(info.data.positionMint, 4)}</Typography>
                                        {info.data.oldPositionMint && <Typography variant="caption" color='divider'>{shortenAddress(info.data.oldPositionMint, 4)}</Typography>}
                                    </Box>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </Box>
    );
}